import { Product } from '~/composables/useAPI/types'
import { joinURL } from 'ufo'
import { useQueryClient } from '@tanstack/vue-query'
import { apiLeaseInfoOptions } from '@register'

export default defineNuxtRouteMiddleware(async (to) => {
  const { data } = useAuth()
  const { $updateAbility, $can } = useNuxtApp()
  $updateAbility(data.value)

  // Params
  const divisionId = to.params.companyId as string
  const leaseId = to.params.leaseDocumentId as string

  // Paths
  const leasePath = `/division/${divisionId}/lease/${leaseId}`
  const leaseRegisterPath = joinURL(leasePath, 'lease')
  const leaseManagerPath = joinURL(leasePath, 'manager')
  const isLeaseRegister = to.path.startsWith(leaseRegisterPath)
  const isLeaseManager = to.path.startsWith(leaseManagerPath)

  // Lease info
  const queryClient = useQueryClient()
  const leaseInfo = await queryClient.ensureQueryData(
    apiLeaseInfoOptions({ divisionId, leaseId }),
  )
  const products = leaseInfo.products || []

  // Permissions
  const canAccessRegister =
    products.includes(Product.LEASE_REGISTER) &&
    $can('product', Product.LEASE_REGISTER)
  const canAccessManager =
    products.includes(Product.LEASE_MANAGER) &&
    $can('product', Product.LEASE_MANAGER)

  // Redirect if unauthorized
  if (isLeaseRegister && !canAccessRegister) {
    return navigateTo(leaseManagerPath)
  }
  if (isLeaseManager && !canAccessManager) {
    return navigateTo(leaseRegisterPath)
  }
})
